<template>
    <div>
        <fieldset  v-bind:class="' border-b-2 ' + bcolor + ' mb-2 relative'">
            <div class="">
                
                <div @click="collapsible($event)" class="title-wrapper flex cursor-pointer justify-between  text-gray-800">
                    <h5 :id="model" :class="model">{{title}}</h5>
                    <i v-bind:class="'icon-angle-circled-down ' + model +'-1 '+color" ></i>
                </div>
                <div :id="model + '-2'" v-bind:class="model + '-2 hidden absolute px-2 pb-4 rounded-lg  shadow-md z-10 border-b-2 '+ bcolor +' bg-white w-full'">
                    <div>
                        <div class="control-wrapper flex">
                            <div :class="'input-wraper flex items-center py-2 w-full'">
                                <label aria-label="Buscar" class="w-full">
                                    <input type="text" :placeholder="placeh" v-model="searchVal"  @keyup="funcFilter($event)" autocomplete="false" v-bind:class="'input-cbx w-full appearance-none block '+ color + ' border ' + bcolor + ' rounded py-2 px-4 mb-3 leading-tight focus:outline-none '">
                                    <input type="hidden" :v-model="value">
                                </label>
                            </div>
                        </div>
                        <div v-if="loading === false">
                            <div  v-if="mutaItems.length > 0" id="options-cbx" class="control-wrapper overflow-y-scroll h-40 limitedHeight ">
                                <a v-for="(item, key,  index) in mutaItems" :key="index" @click="selectOption($event,item.text,item.value)"  href="" class="flex a-option bold text-xl hover:bg-gray-200 p-2">
                                    <div class="container-option w-full flex">
                                        <div class="texts pl-1 text-left">
                                            <h1 class="titleLink text-xs">{{item.text}}</h1>
                                            
                                            <input type="hidden" :v-model="item.value">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div  v-if="mutaItems.length === 0" id="options-cbx" class="control-wrapper  limitedHeight ">
                                <li  class="list-none text-left text-xs">
                                   <a href="#" @click="events($event, searchVal)" >No results matching <strong>{{ searchVal }}</strong>. <kbd>Click</kbd> to create a new one </a> 
                                </li>
                            </div>
                        </div>
                        <div v-else>
                            <div id="options-cbx" class="control-wrapper  limitedHeight ">
                                <li class="list-none text-xs">
                                   loading
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </fieldset>
    </div>
</template>

<script>
    export default {
        name: 'ComboBasic',
        mounted() {
            
        },
        props: {
            // usado para pasar los parametros a mostrar
            items:{},
            model:{
                type: String,
                default: 'default'
            },
            color: {
                type: String,
                default: 'text-blue-500'
            },
            bcolor: {
                type: String,
                default: 'border-blue-500'
            },
            title: {
                type: String,
                default: 'Title'
            },
            placeh: {
                type: String,
                default: 'Search'
            },
            
        },
        data() {
            return {
                nameFilter:'Product filters',
                searchVal:'',
                mutaItems:[],
                value:'',
                loading: true,
            }
            
        },
        
        methods: {
            // Funcion para ocultar o mostrar los inputs en product filter
            collapsible(event){
                event.preventDefault();
                this.mutaItems = this.items 
                // if (this.mutaItems.length > 0) {
                    this.loading = false
                // }
                // console.log(this.mutaItems)
                // this.mutaItems = this.items 
                // console.log('entro')
                const select = document.querySelector(`#${this.model}-2`)
                const ico = document.querySelector(`.${this.model}-1`)
                // console.log(ico)
                select.classList.toggle('active');
                // ico.classList.toggle('icon-down-open ')
                // ico.classList.toggle('icon-up-open ')
                
            },
            funcFilter(event) {
                let val = event.target.value
                let items = this.items 
                val = val.toLowerCase()
                this.mutaItems = []
        
                for (let filter of items) {
                    let text = filter.text.toLowerCase()
                    // console.log(filter)
                    if(text.indexOf(val) !== -1){

                        this.mutaItems.push({flag: filter.flag,text: filter.text, value: filter.value})
                    } 

                }
            }, 
            events(event,val) {
                // console.log('entro 4')
                if (val !== '') {
                    event.preventDefault()
                    this.$emit('getEvent',[val] )
                }else {
                    alert("The field is empty")
                }
            },
            selectOption(event, text = '', val=''){
                event.preventDefault();
                const select = document.querySelector(`#${this.model}-2`)
                const ico = document.querySelector(`.${this.model}-1`)

                let title = document.querySelector(`.${this.model}`).innerHTML= `
                <div class="container-option w-full flex">
                    
                    <div class="texts pl-1">
                        <h5 class="titleLink text-xs pt-1">${text}</h5>
                    </div>
                </div>
                `
                select.classList.toggle('active');
                // ico.classList.toggle('icon-down-open')
                // ico.classList.toggle('icon-up-open')
                this.value = val
                this.searchVal = ''
            }
        },
        watch: {
            value (value){
                this.$emit('input', value)
            },
            items (items){
                // console.log(items)
                this.mutaItems = items 
                // this.loading = false
                if (this.mutaItems.length > 0) {
                    this.loading = false
                }
            },
            
        },
        
    }
</script>

<style >
.cbx-2{
    display: none;
}
.active{
    display: block !important;
}

</style>