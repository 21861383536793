<template>
	<div v-if="hasPermission">
		<Loans />
	</div>
</template>

<script>
// @ is an alias to /src

import Loans from '@/components/content/content-loans/ContentLoans.vue'
import { getPermissions } from '../components/util/liblist'
export default {
	name: 'ViewLoans',
	components: {
		Loans,
	},
	data() {
		return {
			'hasPermission': false
		}
	},
	mounted() {
		if (getPermissions('D')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
